import React from 'react'
import { Row, Col } from 'antd'
import { defaultSortOrder } from '../../../../../utils/table/defaultSortOrder'
import { columnSortTooltip } from '../../../../../utils/table/columnSortTooltip'
import { SORT_TEXTS } from '../../../../../constants/COLUMN_SORTER'
import './styles.modules.scss'

export const LabelColumn = (sortColumn: string, sortOrder: string) => {
  return {
    title: 'Label',
    dataIndex: 'shippingLabel',
    defaultSortOrder: defaultSortOrder('shippingLabel', sortColumn, sortOrder),
    showSorterTooltip: columnSortTooltip({
      dataIndex: 'shippingLabel',
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      ascText: SORT_TEXTS.PRIORITY_ASC,
      descText: SORT_TEXTS.PRIORITY_DESC,
      cancelText: SORT_TEXTS.CANCEL
    }),
    sorter: true,
    className: 'table-column label-column',
    render: (shippingLabel: string) => {
      return (
        <Row>
          <Col span={24}>{shippingLabel}</Col>
        </Row>
      )
    }
  }
}
