import React, { FC, ReactElement, useState } from 'react'
import { Provider } from 'react-redux'
import Navigation from './Navigation'
import OrderNotifications from './OrderNotifications'
import { store } from '../../store'
import DashboardContent from './DashboardContent'
import { DashboardNavigation } from './Navigation/DashboardNavigation'
import { getQueryParamsState, setQueryParamsState } from '../../utils/queryParams'
import { SchedulerDashboardProps } from './types'
import { PusherWrapper } from '../../utils/pusherProvider'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import '../Shared/Ant/App.less'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

export const SchedulerDashboard: FC<SchedulerDashboardProps> = ({
  isReactDashboardTableEnabled,
  isTransactionTypeEnabled,
  companyId,
  isPusherDisabled,
  isRealTimeDashboardEnabled,
  isSweepingEnabled,
  isRootCompany
}): ReactElement => {
  let pusher: PusherWrapper
  const [searchParams, setSearchParams] = useState(getQueryParamsState())

  // There should be only one instance of Pusher on the scheduler
  // dashboard page
  const pusherWrapper = (pusherAppKey: string, pusherAppCluster: string) => {
    if (pusher) return pusher

    return (pusher = new PusherWrapper(pusherAppKey, pusherAppCluster))
  }

  const changeSearchParams = (params: object) => {
    setQueryParamsState(params)
    setSearchParams(params)
  }

  if (window.location.pathname === '/orders') {
    return (
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          {!isReactDashboardTableEnabled && <Navigation ordersPage={true} companyId={companyId} />}
          {isReactDashboardTableEnabled && (
            <>
              <DashboardNavigation
                ordersPage={true}
                companyId={companyId}
                searchParams={searchParams}
                changeSearchParams={changeSearchParams}
                pusherWrapper={pusherWrapper}
              />
              <div className="content" style={isPusherDisabled ? { marginRight: '13px' } : undefined}>
                <DashboardContent
                  companyId={companyId}
                  transactionTypeFlipper={isTransactionTypeEnabled}
                  searchParams={searchParams}
                  changeSearchParams={changeSearchParams}
                  pusherWrapper={pusherWrapper}
                  isRealTimeDashboardEnabled={isRealTimeDashboardEnabled}
                  isSweepingEnabled={isSweepingEnabled}
                  isRootCompany={isRootCompany}
                />
              </div>
            </>
          )}
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Provider>
    )
  }

  return (
    <Provider store={store}>
      <>
        <QueryClientProvider client={queryClient}>
          <DashboardNavigation
            ordersPage={false}
            companyId={companyId}
            searchParams={searchParams}
            changeSearchParams={changeSearchParams}
            pusherWrapper={pusherWrapper}
          />
        </QueryClientProvider>
        <OrderNotifications isRootCompany={isRootCompany} />
      </>
    </Provider>
  )
}
